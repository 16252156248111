:root {
  /*  --mainColor: rgb(76 42 123);*/
  --customBg: -webkit-linear-gradient(left, #01355b, rgb(13 110 253));
  --mainColor: -webkit-linear-gradient(left, #01355b, rgb(13 110 253));
}

.justify-paragraph {
  text-align: justify;
}

.custom-spinner {
  width: 20px !important;
  height: 20px !important;
}

.form-check-input:checked {
  background-color: #4c2a7b !important;
  border-color: #4c2a7b !important;
}

ol,
ul {
  padding-left: 1rem !important;
}

.uderline-heading {
  text-decoration-line: underline;
  text-decoration-style: double;
}

.text-style-none {
  list-style-type: none;
  padding-left: 0px !important;
  margin: 0;
}

.text-style-none li {
  padding-left: 0px;
}

.successBtn {
  background-color: rgb(11, 222, 11) !important;
  color: whitesmoke !important;
}

.pointer {
  cursor: pointer;
}

.text_main_color {
  color: var(--mainColor) !important;
}

.btn-outline-info:hover {
  background-color: var(--mainColor) !important;
  border-color: white;
  color: white !important;
}

.btn-outline-info {
  color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
}

.form-control-lg {
  min-height: calc(2.44375rem + 2px);
  padding: 0.45rem 1rem;
  font-size: 0.825rem;
  border-radius: 15px !important;
}

.form-control {
  border-radius: 15px !important;
}

.form-select-lg {
  border-radius: 15px !important;
}

/* buttons */
.btn {
  border-radius: 10px !important;
  padding: 10px 10px 10px 10px;
}

/* input errors */
.error {
  font-size: 12px !important;
}

/* common Question */

.common-question-heading {
  font-size: 2rem;
  color: black;
  margin-top: 5%;
}

.input-form-radius {
  border-radius: 30px;
}

.question-card {
  border-radius: 15px !important;
}

/* navbar */

.navbar_logo {
  width: 170px;
  height: 25px;
}
#test {
  color: red;
}

/* job by  */
.jopJobsByBtn {
  white-space: nowrap !important;
  width: auto !important;
}

/* jobs by city */

.alphabets {
  color: #4c2a7b;
  font-weight: 800;
}
.alphabet-card {
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
  /* Add other styling here if needed */
}

.alphabet-card:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #6516d5;
}
.alphabets-card-body {
  width: 44px;
}
.alphabet-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: 100%;
}
.alpha-card {
  margin-top: 20px;
  cursor: pointer;
}

/* steps form */
.step-container {
  position: relative;
}

.progress-container {
  position: absolute;
  top: 22%;
  left: 0;
  right: 0;
  height: 4px;
  background: #e0e0e0;
  border-radius: 2px;
  z-index: 0;
  width: 77%;
  text-align: center;
  margin: 0 auto;
}

.progress {
  position: absolute;
  height: 100%;
  background: #4caf50;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  color: white;
  font-weight: bold;
}

.step.completed {
  background: #4caf50;
}

.step.activeStep {
  border: 2px solid #4caf50;
}

.step-label {
  margin-top: 5px;
  font-weight: bold;
}

.progress,
.progress-stacked {
  --bs-progress-bg: #4caf50 !important;
}

/* Intel input phone */
.react-international-phone-input {
  width: 100%;
  border-radius: 6px;
}

/* React Select  Start*/
.css-1p3m7a8-multiValue {
  border-radius: 5px !important;
  color: whitesmoke !important;
  background-color: #4c2a7b !important;
}

.css-9jq23d {
  color: hsl(0deg 34.92% 98.43%) !important;
}

.select__control {
  border-radius: 12px !important;
  min-height: calc(2.4435rem + 2px) !important;
  font-size: 0.815rem !important;
}

.basic-multi-select {
  border-radius: 40px !important;
}
.css-13cymwt-control {
  border-radius: 10px !important;
}

/* pagination */
.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: rgb(76 42 123) !important;
  border-color: whitesmoke !important;
}

.form-container .slide-controls {
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  border: 1px solid lightgrey;
  overflow: hidden;
  margin: 20px 0 10px 0;
  border-radius: 10px;
  position: relative;
}

.slide-controls .slide {
  width: 100%;
  height: 100%;
  font-size: 13px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  z-index: 1;
  transition: all 0.6s ease;
}

.slide-controls .signup {
  color: #212121;
}

.slide-controls .slide-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
  z-index: 0;
  /*    background: -webkit-linear-gradient(left, #502e84, #2b1844);*/
  background: var(--customBg) !important;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.slide-controls input[type="radio"] {
  display: none;
}

#signup:checked ~ .slide-tab {
  left: 50%;
}

#signup:checked ~ .signup {
  color: #fff;
}

#signup:checked ~ .login {
  color: #212121;
}

.form-container .form-inner {
  display: flex;
  width: 200%;
}

.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.react-international-phone-country-selector-button {
  border-radius: 15px 0 0 15px !important;
  width: 60px;
  height: 40px !important;
}

.react-international-phone-input {
  border-radius: 0 15px 15px 0 !important;
  height: 40px !important;
}

.logo {
  width: 300px;
}
.customBg {
  background: var(--customBg) !important;
}

.wdcBgColor {
  background-color: #01355b !important;
}
.ParentContainer {
  display: flex;
  flexdirection: column;
  alignitems: center;
  justifycontent: center;
  height: 100vh;
  background: #f0f2f5;
}

.ParentformContainer {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
}

.wdcBgColor {
  background-color: #01355b !important;
}
.ParentContainer {
  display: flex;
  flexdirection: column;
  alignitems: center;
  justifycontent: center;
  height: 100vh;
  background: #f0f2f5;
}

.ParentformContainer {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
}

.custom-btn {
  min-width: 120px;
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #01355b;
  border: 1px solid #01355b;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.375rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-btn:hover {
  color: #fff;
  background-color: #01355b;
  border-color: #0056b3;
}

.custom-btn:focus {
  color: #fff;
  background-color: #01355b;
  border-color: #004085;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.custom-btn:disabled,
.custom-btn.disabled {
  color: #01355b;
  background-color: #dddddd;
  border-color:;
  opacity: 0.65;
  pointer-events: none;
}

.custom-btn:active {
  color: #fff;
  background-color: #004085;
  border-color: #003768;
}

.custom-btn:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.invisible-phone {
  margin-bottom: 0.87rem !important;
}
